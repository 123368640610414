var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Popup, Marker, useMap } from "react-leaflet";
import Leaflet from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Suppliers, Intervenants, OrderGiver, PublicNotices, Market, } from "../Styles/Map/Markers/Markers-styles";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import MapContext from "../../../application/Context/MapContext";
export var CompanyMarkers = function (_a) {
    var _b, _c;
    var data = _a.data;
    var t = useTranslation(['news']).t;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var currentAuthStatus = ((_c = document.getElementById("auth-user")) === null || _c === void 0 ? void 0 : _c.value) || false;
    var isAuthenticated = currentAuthStatus == 'True' ? true : false;
    var _d = __read(React.useState([]), 2), companiesState = _d[0], setCompaniesState = _d[1];
    var _e = __read(React.useState(false), 2), isLoadingLocateUser = _e[0], setIsLoadingLocateUser = _e[1];
    var map = useMap();
    if (companiesState != data) {
        setCompaniesState(data);
    }
    var getUserLocation = function () {
        setIsLoadingLocateUser(true);
        map.locate().on("locationfound", function (e) {
            if (e) {
                setIsLoadingLocateUser(false);
            }
            map.setView(e.latlng, 11);
        }).on("locationerror", function () {
            setIsLoadingLocateUser(false);
        });
    };
    var Company = function (item) {
        var c = item.company;
        var title = isAuthenticated ? (_jsx("a", __assign({ href: langCode == "fr" ? "/fr".concat(c.absolute_url_fr) : "/en".concat(c.absolute_url_en) }, { children: _jsx("span", { children: langCode == "fr" ? c.nom_legal_fr : c.nom_legal_en }) }))) : (_jsx("span", { children: langCode == "fr" ? c.nom_legal_fr : c.nom_legal_en }));
        return (_jsxs("div", __assign({ className: "popup" }, { children: [_jsxs("div", __assign({ className: "popup__header" }, { children: [c.logo_url &&
                            _jsx("img", { className: "popup__image", src: c.logo_url, alt: "Company-logo" }), !c.logo_url &&
                            _jsx("img", { className: "popup__image", src: "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: "Company logo" }), _jsx("div", __assign({ className: "icon-container" }, { children: _jsx("img", { src: c.badge_url, width: 24, height: 24 }) }))] })), _jsx("h4", __assign({ className: "popup__title" }, { children: title })), _jsxs("ul", __assign({ className: "popup__category" }, { children: [c.categorie_entreprise && c.categorie_entreprise.nom &&
                            _jsx("li", { children: c.categorie_entreprise.nom }), c.categorie_donneur_dordre && c.categorie_donneur_dordre.nom &&
                            _jsx("li", { children: c.categorie_donneur_dordre.nom })] })), _jsxs("div", __assign({ className: "popup__desc", style: { display: "flex" } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-marker-blue-fonce.svg"), alt: "location" }), _jsx("p", { children: c.adresses.region_adminsitrative.nom })] })), _jsxs("div", __assign({ className: "popup__desc", style: { display: "flex" } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/apartment-blue-fonce.svg"), alt: "apartment" }), _jsxs("p", { children: [t("MRC"), " ", c.adresses.mrc.nom] })] })), c.site_web &&
                    _jsxs("div", __assign({ className: "popup__desc", style: { display: "flex" } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/mouse-blue-fonce.svg"), alt: "website" }), _jsx("a", __assign({ href: c.site_web, target: "_blank", rel: "noreferrer" }, { children: c.site_web }))] }))] })));
    };
    var iconSuppliers = ReactDOMServer.renderToString(_jsx(Suppliers, {}));
    var customMarkerSuppliers = new Leaflet.DivIcon({
        html: iconSuppliers,
    });
    var iconIntervenants = ReactDOMServer.renderToString(_jsx(Intervenants, {}));
    var customMarkerIntervenants = new Leaflet.DivIcon({
        html: iconIntervenants,
    });
    var iconOrderGiver = ReactDOMServer.renderToString(_jsx(OrderGiver, {}));
    var customMarkerOrderGiver = new Leaflet.DivIcon({
        html: iconOrderGiver,
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "user-location", onClick: getUserLocation }, { children: [!isLoadingLocateUser && _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-marker-blue.svg"), alt: "location" }), isLoadingLocateUser && _jsx("span", { className: "nx-map-locate-spinner spinner-border" })] })), companiesState.filter(function (e) { if (e.adresses.latitude_coordonnate && e.adresses.longitude_coordonnate) {
                return e;
            } }).map(function (c) {
                try {
                    if (c.adresses.latitude_coordonnate && c.adresses.longitude_coordonnate) {
                        var displayIcon = void 0;
                        switch (c.type_company) {
                            case "supplier":
                                displayIcon = customMarkerSuppliers;
                                break;
                            case "intervenant":
                                displayIcon = customMarkerIntervenants;
                                break;
                            case "contractor":
                                displayIcon = customMarkerOrderGiver;
                                break;
                        }
                        return (_jsx(Marker, __assign({ position: [c.adresses.latitude_coordonnate, c.adresses.longitude_coordonnate], icon: displayIcon }, { children: _jsx(Popup, { children: _jsx(Company, { company: c }) }) }), Math.random().toString()));
                    }
                }
                catch (_a) {
                    _jsx("span", {});
                }
            }), companiesState.filter(function (e) { return e.autres_address && e.autres_address.length > 0 && e.autres_address.every(function (addr) { return addr.latitude_coordonnate && addr.longitude_coordonnate; }); }).map(function (c) {
                try {
                    return c.autres_address.map(function (addr) {
                        var displayIcon;
                        switch (c.type_company) {
                            case "supplier":
                                displayIcon = customMarkerSuppliers;
                                break;
                            case "intervenant":
                                displayIcon = customMarkerIntervenants;
                                break;
                            case "contractor":
                                displayIcon = customMarkerOrderGiver;
                                break;
                        }
                        return (_jsx(Marker, __assign({ position: [addr.latitude_coordonnate, addr.longitude_coordonnate], icon: displayIcon }, { children: _jsx(Popup, { children: _jsx(Company, { company: c }) }) }), Math.random().toString()));
                    });
                }
                catch (_a) {
                    return _jsx("span", {});
                }
            })] }));
};
export var AvisMarkers = function (_a) {
    var _b;
    var data = _a.data;
    var t = useTranslation(['news']).t;
    var currentAuthStatus = ((_b = document.getElementById("auth-user")) === null || _b === void 0 ? void 0 : _b.value) || false;
    var isAuthenticated = currentAuthStatus == 'True' ? true : false;
    var _c = __read(React.useState([]), 2), projectsState = _c[0], setProjectsState = _c[1];
    if (projectsState != data) {
        setProjectsState(data);
    }
    var Project = function (item) {
        var _a, _b, _c, _d, _e;
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        var av = item.project;
        var title = isAuthenticated ? _jsx("a", __assign({ href: langCode == "fr" ? "/fr".concat(av.absolute_url_fr) : "/en".concat(av.absolute_url_en) }, { children: _jsx("span", { children: langCode == "fr" ? av.nom_fr : av.nom_en }) })) : _jsx("span", { children: langCode == "fr" ? av.nom_fr : av.nom_en });
        return (_jsxs("div", __assign({ className: "project" }, { children: [_jsxs("div", __assign({ className: "project__header" }, { children: [av.entreprise.logo_TEST &&
                            _jsx("img", { src: av.entreprise.logo, alt: "", style: { maxHeight: "100%", margin: "0 auto" } }), !av.entreprise.logo &&
                            _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: "banner", style: { maxHeight: "100%", margin: "0 auto" } }), _jsx("h4", __assign({ className: "project__header__title" }, { children: title }))] })), _jsx("ul", __assign({ className: "project__category" }, { children: ((_b = av.categorie_pro) === null || _b === void 0 ? void 0 : _b.nom_fr) &&
                        _jsx("li", { children: langCode == "fr" ? av.categorie_pro.nom_fr : av.categorie_pro.nom_en }) })), ((_d = (_c = av.adresse) === null || _c === void 0 ? void 0 : _c.mrc) === null || _d === void 0 ? void 0 : _d.nom) &&
                    _jsxs("div", __assign({ className: "project__description" }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/apartment-blue-fonce.svg"), alt: "apartment" }), _jsxs("p", { children: [" ", t("MRC"), " ", av.adresse.mrc.nom, " "] })] })), av.description_fr !== "None" &&
                    _jsx("p", __assign({ className: "project__text" }, { children: langCode === "fr" ? av.description_fr : av.description_en })), av.entreprise &&
                    _jsxs(_Fragment, { children: [_jsx("hr", {}), _jsxs("div", __assign({ className: "company" }, { children: [_jsxs("div", __assign({ className: "image-container" }, { children: [av.entreprise.logo &&
                                                _jsx("img", { src: av.entreprise.logo, alt: "", style: { maxHeight: "100%", margin: "0 auto" } }), !av.entreprise.logo &&
                                                _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: "banner", style: { maxHeight: "100%", margin: "0 auto" } }), ((_e = av.entreprise) === null || _e === void 0 ? void 0 : _e.badge) &&
                                                _jsx("div", __assign({ className: "icon-container" }, { children: _jsx("img", { src: av.entreprise.badge }) }))] })), _jsxs("div", __assign({ className: "company__description" }, { children: [_jsx("h4", { children: langCode == "fr" ? av.entreprise.nom_legal_fr : av.entreprise.nom_legal_en }), _jsxs("ul", __assign({ className: "company__category" }, { children: [av.categorie_projet && av.categorie_projet.nom_fr &&
                                                        _jsx("li", { children: langCode == "fr" ? av.categorie_projet.nom_fr : av.categorie_projet.nom_en }), av.categorie_contrat && av.categorie_contrat.nom_fr &&
                                                        _jsx("li", { children: langCode == "fr" ? av.categorie_contrat.nom_fr : av.categorie_contrat.nom_en })] }))] }))] }))] })] })));
    };
    var iconPublicNotices = ReactDOMServer.renderToString(_jsx(PublicNotices, {}));
    var customPublicNotices = new Leaflet.DivIcon({ html: iconPublicNotices });
    var iconMarket = ReactDOMServer.renderToString(_jsx(Market, {}));
    var customMarkerMarket = new Leaflet.DivIcon({ html: iconMarket });
    return (_jsx(_Fragment, { children: data.map(function (av) {
            try {
                if (av.adresse.latitude_coordonnate && av.adresse.longitude_coordonnate) {
                    var displayIcon = void 0;
                    switch (av.is_avis) {
                        case true:
                            displayIcon = customPublicNotices;
                            break;
                        case false:
                            displayIcon = customMarkerMarket;
                            break;
                    }
                    return (_jsx(Marker, __assign({ position: [
                            av.adresse.latitude_coordonnate,
                            av.adresse.longitude_coordonnate
                        ], icon: displayIcon }, { children: _jsx(Popup, { children: _jsx(Project, { project: av }) }) }), Math.random().toString()));
                }
            }
            catch (_a) {
                _jsx("span", {});
            }
        }) }));
};
export var Markers = function () {
    var _a = React.useContext(MapContext), selectedSectors = _a.selectedSectors, selectedRegions = _a.selectedRegions, selectedCategories = _a.selectedCategories, companies = _a.companies, projects = _a.projects, initialDatas = _a.initialDatas, updatedKey = _a.updatedKey;
    var map = useMap();
    React.useEffect(function () {
        if (!initialDatas) {
            try {
                var AllMarkers = __spreadArray(__spreadArray([], __read(companies), false), __read(projects), false);
                if (AllMarkers.length) {
                    var latlon = [];
                    for (var i = 0; i < AllMarkers.length; i++) {
                        if (AllMarkers[i].adresses) {
                            if (AllMarkers[i].adresses.latitude_coordonnate && AllMarkers[i].adresses.longitude_coordonnate) {
                                latlon.push([
                                    AllMarkers[i].adresses.latitude_coordonnate,
                                    AllMarkers[i].adresses.longitude_coordonnate
                                ]);
                            }
                        }
                        else {
                            if (AllMarkers[i].adresse.latitude_coordonnate && AllMarkers[i].adresse.longitude_coordonnate) {
                                latlon.push([
                                    AllMarkers[i].adresse.latitude_coordonnate,
                                    AllMarkers[i].adresse.longitude_coordonnate
                                ]);
                            }
                        }
                    }
                    if (latlon.length) {
                        if (selectedCategories.length > 0 || selectedRegions.length > 0 || selectedSectors.length > 0) {
                            var bounds = latlon.filter(function (coord) {
                                return Array.isArray(coord) &&
                                    coord.length === 2 &&
                                    !isNaN(coord[0]) &&
                                    !isNaN(coord[1]) &&
                                    coord[0] >= -90 &&
                                    coord[0] <= 90 &&
                                    coord[1] >= -180 &&
                                    coord[1] <= 180;
                            });
                            try {
                                map.fitBounds(bounds, { maxZoom: 10 });
                            }
                            catch (_a) {
                                //
                            }
                        }
                        else {
                            map.flyTo(["55.5877294", "-65.191206"], 5);
                        }
                    }
                    // map.flyTo([companiesState[0].lat, companiesState[0].long], 6);
                }
            }
            catch (_b) {
                //
            }
        }
    }, [updatedKey]);
    var createClusterCustomIconSupplier = function (cluster) {
        return createClusterCustomIcon(cluster, "supplier");
    };
    var createClusterCustomIconIntervenant = function (cluster) {
        return createClusterCustomIcon(cluster, "intervenant");
    };
    var createClusterCustomIconContractor = function (cluster) {
        return createClusterCustomIcon(cluster, "contractor");
    };
    var createClusterCustomIconNotice = function (cluster) {
        return createClusterCustomIcon(cluster, "notice");
    };
    var createClusterCustomIconMarket = function (cluster) {
        return createClusterCustomIcon(cluster, "market");
    };
    var createClusterCustomIcon = function (cluster, classname) {
        return L.divIcon({
            html: "<div class=\"marker-cluster ".concat(classname, "\"> <span> ").concat(cluster.getChildCount(), " </span> </div>"),
            // customMarker is the class name in the styles.css file
            className: "customMarker",
            iconSize: L.point(40, 40, true),
        });
    };
    return (_jsxs(React.Fragment, { children: [_jsx(MarkerClusterGroup, __assign({ chunkedLoading: true, iconCreateFunction: createClusterCustomIconSupplier, polygonOptions: { weight: 0, color: "transparent", opacity: 0 }, maxClusterRadius: 20 }, { children: _jsx(CompanyMarkers, { data: companies.filter(function (e) { return e.type_company == "supplier"; }) }) })), _jsx(MarkerClusterGroup, __assign({ chunkedLoading: true, iconCreateFunction: createClusterCustomIconIntervenant, polygonOptions: { weight: 0, color: "transparent", opacity: 0 }, maxClusterRadius: 20 }, { children: _jsx(CompanyMarkers, { data: companies.filter(function (e) { return e.type_company == "intervenant"; }) }) })), _jsx(MarkerClusterGroup, __assign({ chunkedLoading: true, iconCreateFunction: createClusterCustomIconContractor, polygonOptions: { weight: 0, color: "transparent", opacity: 0 }, maxClusterRadius: 20 }, { children: _jsx(CompanyMarkers, { data: companies.filter(function (e) { return e.type_company == "contractor"; }) }) })), _jsx(MarkerClusterGroup, __assign({ chunkedLoading: true, iconCreateFunction: createClusterCustomIconNotice, polygonOptions: { weight: 0, color: "transparent", opacity: 0 }, maxClusterRadius: 20 }, { children: _jsx(AvisMarkers, { data: projects.filter(function (e) { return e.is_avis; }) }) })), _jsx(MarkerClusterGroup, __assign({ chunkedLoading: true, iconCreateFunction: createClusterCustomIconMarket, polygonOptions: { weight: 0, color: "transparent", opacity: 0 }, maxClusterRadius: 20 }, { children: _jsx(AvisMarkers, { data: projects.filter(function (e) { return !e.is_avis; }) }) }))] }));
};
