var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import MapContext from "../../../../application/Context/MapContext";
import { useTranslation } from "react-i18next";
import { ViewToggler } from "./ViewToggler";
import { ViewList } from "./ViewList";
import { MapContainer, TileLayer } from "react-leaflet";
import { Markers } from "../Markers";
import { Filters } from "../Filters";
import { Card } from "../../shared-components/Ui/Card/Card";
import { CleanFilters } from "../CleanFilters";
import { Legend } from "../Legend";
import { SearchBar } from "../SearchBar";
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
export var View = function () {
    var _a;
    var t = useTranslation(["common"]).t;
    var _b = useContext(MapContext), view = _b.view, updatedKey = _b.updatedKey;
    var currentAuthStatus = ((_a = document.getElementById("auth-user")) === null || _a === void 0 ? void 0 : _a.value) || false;
    var isAuthenticated = currentAuthStatus == 'True' ? true : false;
    // Initialize the map.
    var mapOptions = {
        key: updatedKey,
        center: ["55.5877294", "-65.191206"],
        zoom: 5,
        attributionControl: false,
        scrollWheelZoom: true,
        gestureHandling: true,
        gestureHandlingOptions: {
            duration: 5000,
            text: {
                touch: t("Use two fingers to move the map"),
                scroll: t("Use ctrl + scroll to zoom the map"),
                scrollMac: t("Use command + scroll to zoom the map")
            }
        }
    };
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    return (_jsxs(React.Fragment, { children: [_jsx("div", __assign({ className: "home-header", style: { background: "linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(242,246,249,0) 38%,rgba(230,237,243,1))" } }, { children: _jsxs("div", __assign({ className: "container homepage-padding" }, { children: [_jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-md-6" }, { children: _jsx("h1", { children: t("Bienvenue sur le Réseau d'affaires nordiques") }) })), _jsx("div", __assign({ className: "col-md-6 v-center" }, { children: _jsxs("p", { children: [t("Le Réseau d'affaires nordiques est une initiative de la Société du Plan Nord"), t(" pour mettre en contact les donneurs d'ordres et les fournisseurs œuvrant au nord du 49e parallèle."), _jsx("br", {}), _jsx("br", {}), t("Développée en collaboration avec les gens d'affaires, cette plateforme en ligne répond aux besoins des marchés et aux réalités du territoire nordique.")] }) }))] })), _jsx("br", {}), isAuthenticated &&
                            _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx("div", __assign({ className: "search-bar", id: "nx-home-map-search" }, { children: _jsx(SearchBar, {}) })) })) }))] })) })), _jsxs("div", __assign({ id: "map-wrapper" }, { children: [view === "carte" && (_jsx(React.Fragment, { children: _jsxs(Card, __assign({ topPosition: 60, zIndex: 1001 }, { children: [_jsx(CleanFilters, {}), _jsx(Filters, {})] })) })), view === "carte" && _jsx(ViewToggler, {}), view === "carte" ? (_jsxs(React.Fragment, { children: [_jsxs(MapContainer, __assign({}, mapOptions, { children: [_jsx(TileLayer, { url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=".concat(process.env.GOOGLE_KEY), attribution: "\u00A9 Google Maps" }), _jsx(Markers, {})] })), _jsx(Legend, {})] })) : (_jsx(ViewList, {}))] }))] }));
};
